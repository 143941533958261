import React from "react";
import { Typography, Row, Col } from "antd";
import { Footer } from "antd/lib/layout/layout";

import Wrapper from "../Wrapper";
import WhatsappWidget from "../WhatsappWidget";

import "./footer.scss";

import { Link, useI18next } from "gatsby-plugin-react-i18next";

const FooterComponent = () => {
  const { changeLanguage } = useI18next();

  return (
    <Footer className="footer">
      <WhatsappWidget />
      <Wrapper>
        <Row justify="space-between" gutter={[24, 24]}>
          <Col xs={24} sm={24} md={6}>
            <div>
              <Typography className="footer__title">
                PT. Prabu Cahaya Abadi
              </Typography>
              <Typography
                className="footer__description"
                style={{ marginTop: "10px" }}
              >
                Your Reliable Fuel and Lubricant Partner
              </Typography>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4}>
            <div>
              <Typography className="footer__title">Navigation</Typography>
              <div style={{ marginTop: "10px" }}>
                <Link className="footer__description" to="/">
                  Home
                </Link>
              </div>
              <div>
                <Link className="footer__description" to="/our-business">
                  Our Business
                </Link>
              </div>
              <div>
                <Link className="footer__description" to="/about-us">
                  About Us
                </Link>
              </div>
              <div>
                <Link className="footer__description" to="/contact-us">
                  Contact Us
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4}>
            <div>
              <Typography className="footer__title">Office Address</Typography>
              <Typography
                className="footer__description"
                style={{ marginTop: "10px" }}
              >
                MTH SQUARE BUILDING LT. 3A, NO.10, <br /> JL. MT Haryono Kav. 10
                Jatinegara, <br />
                Jakarta 13330
              </Typography>
            </div>
          </Col>
          <Col xs={24} sm={24} md={5}>
            <div>
              <Typography className="footer__title">Contact Us</Typography>
              <Typography
                className="footer__description"
                style={{ marginTop: "10px" }}
              >
                Direct : +62-212-982-7424
              </Typography>
              <Typography className="footer__description">
                Fax : +62-212-148-1762
              </Typography>
              <div style={{ marginTop: 10 }}>
                <Typography className="footer__description">
                  rido.riyandi@prabusinar9.com
                </Typography>
                <Typography className="footer__description">
                  +62-812-1031-1656
                </Typography>
              </div>
              <div style={{ marginTop: 10 }}>
                <Typography className="footer__description">
                  hafiz.maulana@prabusinar9.com
                </Typography>
                <Typography className="footer__description">
                  +62-812-9089-5424
                </Typography>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={4}>
            <div>
              <Typography className="footer__title">Language</Typography>
              <div style={{ marginTop: "5px" }}>
                <Typography
                  type="link"
                  className="footer__test"
                  onClick={() => changeLanguage("id")}
                >
                  Bahasa Indonesia
                </Typography>
                <Typography
                  type="link"
                  className="footer__test"
                  onClick={() => changeLanguage("en")}
                >
                  English
                </Typography>
              </div>
            </div>
          </Col>
        </Row>
      </Wrapper>
    </Footer>
  );
};

export default FooterComponent;
