import React from "react";
import { Layout, Row, Col, Button } from "antd";
import { StaticImage } from "gatsby-plugin-image";

import Wrapper from "../Wrapper";
import Hamburger from "./components/hamburgerMenu";
import useWindowSize from "../../utils/useWindowDimensions";

import "./header.scss";

import { Link, useI18next } from "gatsby-plugin-react-i18next";

const { Header } = Layout;

const HeaderComponent = () => {
  const pathname = typeof window !== "undefined" && window.location.pathname;

  const { windowWidth } = useWindowSize();

  const { language, changeLanguage } = useI18next();

  return (
    <Header className="header">
      <Wrapper>
        <div className="header__container">
          <Row justify="space-between">
            <Col>
              <Link to="/">
                <StaticImage
                  src="../../images/logo.png"
                  alt="Prabu Cahaya Abadi Logo"
                  placeholder="blurred"
                  layout="fixed"
                  width={74}
                />
              </Link>
            </Col>
            {windowWidth < 1200 ? (
              <Col>
                <Row>
                  {language === "id" ? (
                    <Col>
                      <div className="header__container__menu__hamburger-flag">
                        <StaticImage
                          src="../../images/indonesia-flag.png"
                          alt="id-button"
                          placeholder="blurred"
                          layout="fixed"
                          width={20}
                          onClick={() => changeLanguage("en")}
                        />
                      </div>
                    </Col>
                  ) : (
                    <Col>
                      <div className="header__container__menu__hamburger-flag">
                        <StaticImage
                          src="../../images/english-flag.png"
                          alt="en-button"
                          placeholder="blurred"
                          layout="fixed"
                          width={20}
                          onClick={() => changeLanguage("id")}
                        />
                      </div>
                    </Col>
                  )}
                  <Col>
                    <Hamburger />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col>
                <Row className="header__container__menu">
                  <Col>
                    <Button
                      className={
                        pathname === "/" || pathname === "/en/"
                          ? "header__container__menu__text__active"
                          : "header__container__menu__text"
                      }
                      type="text"
                    >
                      <Link to="/">Home</Link>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className={
                        pathname === "/our-business" ||
                        pathname === "/en/our-business"
                          ? "header__container__menu__text__active"
                          : "header__container__menu__text"
                      }
                      type="text"
                    >
                      <Link to="/our-business">Our Business</Link>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className={
                        pathname === "/about-us" || pathname === "/en/about-us"
                          ? "header__container__menu__text__active"
                          : "header__container__menu__text"
                      }
                      type="text"
                    >
                      <Link to="/about-us">About Us</Link>
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className={
                        pathname === "/contact-us" ||
                        pathname === "/en/contact-us"
                          ? "header__container__menu__text__active"
                          : "header__container__menu__text"
                      }
                      type="text"
                    >
                      <Link to="/contact-us">Contact Us</Link>
                    </Button>
                  </Col>
                  {language === "id" ? (
                    <Col>
                      <div className="header__container__menu__flag">
                        <StaticImage
                          src="../../images/indonesia-flag.png"
                          alt="id-button"
                          placeholder="blurred"
                          layout="fixed"
                          width={20}
                          onClick={() => changeLanguage("en")}
                        />
                      </div>
                    </Col>
                  ) : (
                    <Col>
                      <div className="header__container__menu__flag">
                        <StaticImage
                          src="../../images/english-flag.png"
                          alt="en-button"
                          placeholder="blurred"
                          layout="fixed"
                          width={20}
                          onClick={() => changeLanguage("id")}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </Wrapper>
    </Header>
  );
};

export default HeaderComponent;
