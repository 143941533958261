import * as React from "react";

import "./whatsappWidget.scss";

import whatsappImg from "../../images/whatsapp-widget.png";

const WhatsappWidget = () => {
  return (
    <div
      className="whatsapp-widget"
      onClick={() =>
        window.open(
          "https://wa.me/081210311656?text=Halo%2C%20saya%20ingin%20bertanya%20mengenai%20produk%20yang%20ditawarkan%20oleh%20PT%20Prabu%20Cahaya%20Abadi.",
          "_blank"
        )
      }
    >
      <img
        className="whatsapp-widget__image"
        alt="whatsapp-widget"
        src={whatsappImg}
      />
    </div>
  );
};

export default WhatsappWidget;
