import React from "react";
import { Divider, Dropdown, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "gatsby-plugin-react-i18next";

import "./hamburger.scss";

const menu = () => (
  <Menu className="menu">
    <Menu.Item key="0">
      <Link className="menu__text" to="/">
        Home
      </Link>
    </Menu.Item>
    <Divider className="menu__divider" />
    <Menu.Item key="1">
      <Link className="menu__text" to="/our-business">
        Our Business
      </Link>
    </Menu.Item>
    <Divider className="menu__divider" />
    <Menu.Item key="2">
      <Link className="menu__text" to="/about-us">
        About us
      </Link>
    </Menu.Item>
    <Divider className="menu__divider" />
    <Menu.Item key="3">
      <Link className="menu__text" to="/contact-us">
        Contact us
      </Link>
    </Menu.Item>
  </Menu>
);

const Hamburger = () => {
  return (
    <div className="hamburger">
      <Dropdown
        overlay={menu()}
        trigger={["click"]}
        overlayClassName="hamburger__dropdown"
      >
        <MenuOutlined className="hamburger__dropdown__icon" />
      </Dropdown>
    </div>
  );
};

export default Hamburger;
